// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import naloxoneReducer from './naloxoneBySite';
import dropdownsReducer from './dropdown';
import salesReportReducer from './salesReport';
import rniInventoryReducer from './rniInventory';
import usersReducer from './users';

const store = configureStore({
  reducer: {
    naloxone: naloxoneReducer,
    dropdowns: dropdownsReducer,
    salesReport: salesReportReducer,
    rniInventory: rniInventoryReducer,
    users: usersReducer,
  }
});

export default store;
