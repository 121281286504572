import React from 'react';
import { Container, Paper, Button } from '@material-ui/core';
import { IMAGES_URL } from '../../constants/Images';

const SessionTimeout = (props) => {

  const goToLogin = () => {
		window.location.href = '/';
  }

  return (
    <Container component="main" maxWidth="xs">
      <Paper style={{
        marginTop: '2em',
        padding: '1.5em',
        textAlign: 'center',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`
      }}>
        <img style={{width: '100%'}} src={IMAGES_URL.FFF_LOGO} alt="FFF Logo" />
        <h2>Your session has expired.</h2>
        <Button
          onClick={goToLogin}
          type="button"
          variant="contained"
          color="primary"
          style={{backgroundColor: '#558db1'}}
        >
          LOGIN AGAIN
        </Button>
      </Paper>
    </Container>
  );
}

export default SessionTimeout;