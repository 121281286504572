import { config } from '../config/config'

export const callGetAPI = async ({ path }) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('id_token')}`  // Replace with your actual token or header value
  };
  const response = await fetch(`${config.baseUrl}${path}`,
    {
      method: 'GET',
      headers
    }
  )
  const jsonData = await response.json()
  return jsonData
}
