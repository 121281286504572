import { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Box, Container } from '@mui/material'
import { exportExcel } from '../../utils/exportExcel'
import PropTypes from 'prop-types'
import NavBar from '../../components/NavBar/NavBar'
import MUIDataTables from '../../components/Table/MUIDataTables'
import { dispensedReportColDef } from '../../config/tableConfig/dispensedReportColDef'
import MapGraphDispensedReport from '../../components/MapGraph/MapGraphDispensedReport'
import BarGraphDispensedReport from '../../components/BarGraph/BarGraphDispensedReport'
import SearchAndFiltersDispensedReport from '../../components/SearchAndFilters/SearchAndFiltersDispensedReport'
import { fetchDashboardData, fetchExcelData, fetchGraphCountyData, fetchGraphData, fetchMapCountyData, fetchMapData } from '../../redux/dispensedReport';
import { fetchCountyOptions, fetchCustomersOptions, fetchProductsOptions, fetchStateOptions } from '../../redux/dropdown';

const DispensedReport = ({ open, title, onMenuClick }) => {
  const [reset, setReset] = useState(false)
  const [customer, setCustomer] = useState()
  const [product, setProduct] = useState()
  const [county, setCounty] = useState()
  const [state, setState] = useState()
  const [expiration, setExpiration] = useState()
  const [year, setYear] = useState({ label: '2023', value: 2023 })
  const [isLoading, toggleLoading] = useState(false)
  const [sort, setSort] = useState({ label: 'Top', value: 'DESC' })
  const [stateName, setStateName] = useState()
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5
  })
  const { records, mapData, graphData, exportRecords, status, error } = useSelector((state) => state.naloxone);
  const { customersOptions, statesOptions, countyOptions, productsOptions } = useSelector((state) => state.dropdowns);
  const dispatch = useDispatch();

  const isInitialRender = useRef(true)

  useEffect(() => {
    const fetchCustomer = async () => {
      dispatch(fetchCustomersOptions())
    }
    fetchCustomer()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      toggleLoading(true)
      const queryParams = queryParamsGenerate()
      dispatch(fetchDashboardData(queryParams));
      dispatch(fetchGraphData(queryParams));
      dispatch(fetchMapData(queryParams));
      dispatch(fetchProductsOptions())
      dispatch(fetchCountyOptions())
      dispatch(fetchStateOptions())
      isInitialRender.current = false
    }
    if (customersOptions.length) fetchData()
  }, [customersOptions])

  useEffect(() => {
    if (isInitialRender.current) {
      return
    }
    toggleLoading(false)
  }, [records])

  useEffect(() => {
    const fetchPaginatedRecords = async () => {
      const queryParams = queryParamsGenerate()
      toggleLoading(true)
      dispatch(fetchDashboardData(queryParams))
      dispatch(fetchGraphData(queryParams))
      dispatch(fetchMapData(queryParams))
    }
    if (isInitialRender.current) {
      return
    }
    fetchPaginatedRecords()
  }, [paginationModel, reset])

  useEffect(() => {
    if (isInitialRender.current) {
      return
    }
    if (!reset)
      setPaginationModel({
        page: 0,
        pageSize: 5
      })
  }, [county, product, customer, reset, year, expiration])

  useEffect(() => {
    if (isInitialRender.current) {
      return
    }
    const queryParams = queryParamsGenerate()
    const fetchGraph = async () => {
      stateName
        ? dispatch(fetchGraphCountyData(queryParams))
        : dispatch(fetchGraphData(queryParams))
    }
    fetchGraph()
  }, [sort])

  useEffect(() => {
    const fetchData = async () => {
      if (state) {
        const queryParams = queryParamsGenerate()
        dispatch(fetchDashboardData(queryParams))
      }
    }
    fetchData()
  }, [state])

  const queryParamsGenerate = () => {
    let queryParams = {}
    if (customer?.Customer_ID) {
      queryParams.customer = customer?.Customer_ID
    }
    if (product?.product_id) {
      queryParams.product = product?.product_id
    }
    if (county?.County) {
      queryParams.county = county?.County
    }
    if (state?.State) {
      queryParams.state = state?.State
    }
    if (year?.value) {
      queryParams.year = year?.value
    }
    if (expiration?.value) {
      queryParams.expiration = expiration?.value
    }
    if (sort?.value) {
      queryParams.sort = sort?.value
    }
    return JSON.stringify(queryParams)
  }

  const resetMap = () => {
    setReset(true)
    setCustomer(undefined)
    setProduct(undefined)
    setCounty(undefined)
    setExpiration(undefined)
    setState(undefined)
    setYear({ label: '2023', value: 2023 })
    setStateName()
  }
  const handleClick = async (name) => {
    setStateName(name)
    const query = JSON.stringify({ state: name })
    dispatch(fetchGraphCountyData(query))
  }

  const handleExport = async () => {
    const queryParams = queryParamsGenerate()
    dispatch(fetchExcelData(queryParams))

    exportExcel({
      rows: exportRecords,
      fileName: 'Pharmacies Dispense Report'
    })
  }

  return (
    <>
      <NavBar onMenuClick={onMenuClick} open={open} title={title} />
      <Container
        style={{
          overflow: 'auto',
          marginTop: '10px',
          transition: 'width 0.5s',
          maxWidth: open ? 'calc(100vw - 230px)' : '100%'
        }}
      >
        <SearchAndFiltersDispensedReport
          setReset={resetMap}
          customer={customer}
          setCustomer={setCustomer}
          customersOptions={customersOptions}
          product={product}
          setProduct={setProduct}
          productsOptions={productsOptions}
          county={county}
          setCounty={setCounty}
          countyOptions={countyOptions}
          state={state}
          setState={setState}
          statesOptions={statesOptions}
          expiration={expiration}
          setExpiration={setExpiration}
          year={year}
          setYear={setYear}
          handleExport={handleExport}
        />
        <Box display={'flex'} margin={2}>
          <MapGraphDispensedReport
            containerName="inventory"
            title="Dispensed Report"
            handleClick={handleClick}
            setReset={setReset}
            mapData={mapData}
            isLoading={isLoading}
            state={state}
          />
          <BarGraphDispensedReport
            containerName="salesData"
            graphData={graphData}
            isLoading={isLoading}
            sort={sort}
            setSort={setSort}
          />
        </Box>
        <Box display={'flex'} margin={2}>
          <MUIDataTables
            records={records}
            isLoading={isLoading}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            colDef={dispensedReportColDef}
          />
        </Box>
      </Container>
    </>
  )
}
DispensedReport.propTypes = {
  open: PropTypes.string,
  title: PropTypes.string,
  onMenuClick: PropTypes.func.isRequired
}

export default DispensedReport
