// src/counterSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAuth, signInWithCustomToken, signOut } from 'firebase/auth';
import { callGetAPI } from '../api/api';
import { backendRoutes } from '../config/backendRoutes';
import { auth } from '../auth/firebase';

const initialState = {
  user: null,
  loading: false,
  error: null,
  authUser: null,
  isProcessing: false,
  reportQueue: [],
}

export const handleRniAuthToken = createAsyncThunk(
  'auth/handleRniAuthToken',
  async (token, { rejectWithValue }) => {
    try {
      // const auth = getAuth();
      await signOut(auth);
      const userCredential = await signInWithCustomToken(auth, token);
      const idToken = await userCredential.user.getIdToken();
      const uid = userCredential.user.uid;

      localStorage.setItem('uid', uid);
      localStorage.setItem('id_token', idToken);
      localStorage.setItem('authUser', JSON.stringify(userCredential.user));

      return { token: idToken, uid };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const handleRniAuthPageRefresh = createAsyncThunk(
  'auth/handleRniAuthPageRefresh',
  async (_, { rejectWithValue }) => {
    try {
      const uid = localStorage.getItem('uid');
      const idToken = localStorage.getItem('id_token');
      if (!uid || !idToken) {
        throw new Error('User is not authenticated.');
      }
      return { token: idToken, uid };
    } catch (error) {
      // window.location.href = '/logout?authError=true';
      return rejectWithValue(error.message);
    }
  }
);

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setAuthProcessing: (state, action) => {
      state.isProcessing = action.payload;
    },
    setAuthUser: (state, action) => {
      state.authUser = action.payload;
    },
    setReportQueue: (state, action) => {
      state.reportQueue = action.payload;
    },
    logoutUser: async (state) => {
      state.authUser = null;
      state.user = null;
      state.reportQueue = [];
      // await signOut(auth);
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = '/'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleRniAuthToken.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(handleRniAuthToken.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
      })
      .addCase(handleRniAuthToken.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(handleRniAuthPageRefresh.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(handleRniAuthPageRefresh.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
      })
      .addCase(handleRniAuthPageRefresh.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});
export const { setAuthProcessing, setAuthUser, setReportQueue, logoutUser } = usersSlice.actions;

// Selectors
export const selectAuthUser = (state) => state.authUser;
export const selectReportQueue = (state) => state.reportQueue;

export default usersSlice.reducer;
