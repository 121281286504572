import { AppBar, Toolbar, IconButton, Typography } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import PropTypes from 'prop-types'
import { AccountCircle } from '@material-ui/icons'
import MenuListComposition from "../MenuListComposition/MenuListComposition";
import './NavBar.css'

const NavBar = ({ onMenuClick, title }) => {
  return (
    <AppBar
      position="static"
      className="app-bar"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        transition: 'width 0.5s'
      }}
      style={{ transition: 'width 0.5s', width: '100%' }}
    >
      <Toolbar className="toolbar">
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={onMenuClick}
          sx={{ display: { sm: 'block' } }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
        >
          {title}
        </Typography>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          // onClick={handleMenu}
          color="inherit"
          sx={{ display: { sm: 'block' } }}
        >
          {/* <AccountCircle /> */}
          <MenuListComposition />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

NavBar.propTypes = {
  onMenuClick: PropTypes.func.isRequired,
  title: PropTypes.string
}

export default NavBar
