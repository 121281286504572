import React from 'react';
import { signOut } from 'firebase/auth';

const Logout = (props) => {

  const [logoutStarted, setLogoutStarted] = React.useState(false);
	const clearSession = async () => {
		await signOut();
		sessionStorage.clear();
		localStorage.clear();
	}

  (async () => {
    if (!logoutStarted) {
			setLogoutStarted(true);
		}
		if (logoutStarted) {
			return;
		}
    const params = new URLSearchParams(props.location.search);
    let logoutPath = null;
    if (params.get('sessionTimeout')) {
      logoutPath = '/session-timeout'
    }
    if (params.get('authError')) {
      logoutPath = '/auth-error'
    }

    if (logoutPath) {
      await clearSession();
      window.location.href = logoutPath
      return;
    }

    await clearSession();
		window.location.href = '/';
  })()

  return (
    <div></div>
  )
};

export default Logout