import Highcharts from 'highcharts'
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import jsonata from 'jsonata'
import { generateBarGraphs } from '../../utils/generateBarGraphs'
import { Box, CircularProgress } from '@mui/material'
// import { debounce } from 'lodash'

const BarGraphDispensedReport = ({
  containerName,
  graphData,
  isLoading
  // sort = { label: 'Top', value: 'ASC' },
  // setSort = () => null
}) => {
  const [xAxis, setXAxis] = useState([])
  // const [overdoseDeath, setOverDoseDeath] = useState([])
  const [pharmacies, setPharmacies] = useState([])
  const [dispensedQuantity, setDispensedQuantity] = useState([])
  const [barValue] = useState(5)

  useEffect(() => {
    const fn = async () => {
      setXAxis(await jsonata('[State]').evaluate(graphData.slice(0, barValue)))
      // setOverDoseDeath(
      //   await jsonata('[Max_Drug_Overdose_Deaths.$number()]').evaluate(
      //     graphData.slice(0, barValue)
      //   )
      // )
      setPharmacies(
        await jsonata('[Pharmacies.$number()]').evaluate(
          graphData.slice(0, barValue)
        )
      )
      setDispensedQuantity(
        await jsonata('[dispensedQty.$number()]').evaluate(
          graphData.slice(0, barValue)
        )
      )
    }
    fn()
  }, [graphData, isLoading, barValue])
  // const debouncedHandleChange = debounce((value) => {
  //   value ? setBarValue(parseInt(value)) : setBarValue(5)
  // }, 500) // 500ms debounce delay

  const prevXAxis = useRef(xAxis)
  const prevDispensedQuantity = useRef(dispensedQuantity)

  useEffect(() => {
    const currentXAxisString = JSON.stringify(xAxis)
    const currentDispensedQuantityString = JSON.stringify(dispensedQuantity)

    if (
      currentXAxisString !== JSON.stringify(prevXAxis.current) ||
      currentDispensedQuantityString !==
        JSON.stringify(prevDispensedQuantity.current)
    ) {
      const options = {
        chart: {
          type: 'variwide',
          zoomType: 'xy'
        },
        title: {
          text: 'Dispensed Qty vs Pharmacies',
          align: 'left',
          style: {
            fontSize: '13px' // Adjust the font size here
          }
        },

        plotOptions: {
          series: {
            pointWidth: 30, // Adjust this value to control the spacing between bars
            // groupPadding: 0,
            // pointPadding: 0,
            grouping: true,
            borderWidth: 0
          },
          bar: {
            groupPadding: 0,
            pointPadding: 0 // Adjust this value to control the gap between bars
          }
        },
        xAxis: [
          {
            categories: xAxis,
            crosshair: true
          }
        ],
        colors: ['#55c2ec', '#0064a1'],
        yAxis: [
          {
            // Primary yAxis
            labels: {
              format: '{value}',
              style: {
                color: Highcharts.getOptions().colors[2]
              }
            },
            title: {
              enabled: false,
              text: '2022',
              style: {
                color: Highcharts.getOptions().colors[2]
              }
            },
            opposite: true
          },

          {
            // Secondary yAxis
            gridLineWidth: 0,
            title: {
              enabled: false,
              text: 'Overdose Death',
              style: {
                color: Highcharts.getOptions().colors[0]
              }
            },
            labels: {
              format: '{value}',
              style: {
                color: Highcharts.getOptions().colors[0]
              }
            }
          }
        ],
        tooltip: {
          shared: true
        },
        legend: {
          enabled: true
        },
        series: [
          {
            name: 'Pharmacies',
            type: 'column',
            yAxis: 1,
            data: pharmacies
          },
          {
            name: 'Dispensed Qty',
            type: 'column',
            yAxis: 1,
            data: dispensedQuantity
          }
        ],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                legend: {
                  floating: false,
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom',
                  x: 0,
                  y: 0
                },
                yAxis: [
                  {
                    labels: {
                      align: 'right',
                      x: 0,
                      y: -6
                    },
                    showLastLabel: false
                  },
                  {
                    labels: {
                      align: 'left',
                      x: 0,
                      y: -6
                    },
                    showLastLabel: false
                  },
                  {
                    visible: false
                  }
                ]
              }
            }
          ]
        }
      }
      generateBarGraphs({ containerName, options })
      prevXAxis.current = xAxis
      prevDispensedQuantity.current = dispensedQuantity
    }
  }, [xAxis, dispensedQuantity])
  return (
    <>
      {isLoading && (
        <div
          style={{
            margin: 5,
            height: '400px',
            width: '100%',
            zIndex: 2000,
            alignContent: 'center',
            textAlignLast: 'center'
          }}
        >
          <CircularProgress />
        </div>
      )}
      <Box
        style={{
          width: '100%',
          padding: '5px',
          zIndex: 2000,
          display: isLoading ? 'none' : 'block'
        }}
      >
        {/* <Box display={'flex'} style={{ backgroundColor: 'white' }}>
          <Autocomplete
            // key={year}
            className={`global-font`}
            labelId="year"
            options={[
              { label: 'Top', value: 'DESC' },
              { label: 'Bottom', value: 'ASC' }
            ]}
            getOptionKey={(option) => option.value}
            getOptionLabel={(option) => option.label}
            value={sort}
            renderInput={(params) => <TextField {...params} label="Sort" />}
            onChange={(e, newValue) => setSort(newValue)}
            style={{
              width: '100%',
              alignSelf: 'start',
              zIndex: 1999,
              backgroundColor: 'white',
              padding: '10px'
            }}
          />
          <TextField
            label="Total Bars"
            style={{ margin: '10px' }}
            onChange={(e) => debouncedHandleChange(e.target.value)}
            value={barValue}
          />
        </Box> */}
        <div
          id={containerName}
          style={{ width: '100%', height: '100%', zIndex: 2000 }}
        ></div>
      </Box>
    </>
  )
}

BarGraphDispensedReport.propTypes = {
  containerName: PropTypes.string.isRequired,
  graphData: PropTypes.array,
  isLoading: PropTypes.bool,
  sort: PropTypes.object,
  setSort: PropTypes.func
}
export default React.memo(BarGraphDispensedReport)
