export const dispensedReportColDef = [
  {
    name: 'Customer_ID',
    label: 'Customer ID'
  },
  {
    name: 'Customer',
    label: 'Customer Name'
  },
  {
    name: 'Year',
    label: 'Year'
  },
  {
    name: 'Product_ID',
    label: 'Product ID'
  },
  {
    name: 'Product',
    label: 'Product Name'
  },
  {
    name: 'NDC',
    label: 'NDC'
  },
  {
    name: 'Dispensed_Qty',
    label: 'Dispensed Qty'
  },
  {
    name: 'Lot',
    label: 'Lot'
  },
  {
    name: 'Status',
    label: 'Status'
  },
  {
    name: 'Zip_Code',
    label: 'ZIP'
  },
  {
    name: 'State',
    label: 'State'
  },
  {
    name: 'City',
    label: 'City'
  },
  {
    name: 'FIPS',
    label: 'FIPS'
  },
  {
    name: 'County',
    label: 'County'
  },
  {
    name: 'Expiration',
    label: 'Expiration'
  },
  {
    name: 'Address',
    label: 'Address'
  },
  {
    name: 'GPO',
    label: 'GPO'
  },
  {
    name: 'IDN',
    label: 'IDN'
  },
  {
    name: 'Class_of_Trade',
    label: 'Class of Trade'
  },
  {
    name: 'Facility_Type',
    label: 'Facilty Type'
  },
  {
    name: 'Facility_Type_Desc',
    label: 'Facility Type Desc'
  },
  {
    name: 'Date',
    label: 'Date',
    customBodyRender: (value) => value.value
  },
  {
    name: 'Month',
    label: 'Month'
  },
  {
    name: 'id',
    label: 'id'
  }
]
