// src/counterSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { callGetAPI } from '../api/api';
import { backendRoutes } from '../config/backendRoutes';

const initialState = {
  records: [],
  graphData: [],
  mapData: [],
  exportRecords: [],
  status: '',
  error: ''
};

export const fetchDashboardData = createAsyncThunk('app/fetchDashboardData', async (queryParams) => {
  const res = await callGetAPI({
    path: `${backendRoutes.naloxoneOnHand}?query=${queryParams}`
  })
  return res.message;
})

export const fetchGraphData = createAsyncThunk('app/fetchGraphData', async (queryParams) => {
  const res = await callGetAPI({
    path: `${backendRoutes.naloxoneOnHandGraph}?query=${queryParams}`
  })
  return res.message;
})
export const fetchMapData = createAsyncThunk('app/fetchMapData', async (queryParams) => {
  const res = await callGetAPI({
    path: `${backendRoutes.naloxoneOnHandMap}?query=${queryParams}`
  })
  return res.message;
})
export const fetchGraphCountyData = createAsyncThunk('app/fetchGraphCountyData', async (queryParams) => {
  const res = await callGetAPI({
    path: `${backendRoutes.naloxoneOnHandGraphCounty}?query=${queryParams}`
  })
  return res.message;
})
export const fetchMapCountyData = createAsyncThunk('app/fetchMapCountyData', async (queryParams) => {
  const res = await callGetAPI({
    path: `${backendRoutes.naloxoneOnHandMapCounty}?query=${queryParams}`
  })
  return res.message;
})

export const fetchExcelData = createAsyncThunk('app/fetchExcelData', async (queryParams) => {
  const res = await callGetAPI({
    path: `${backendRoutes.naloxoneOnHandExport}?query=${queryParams}`
  })
  return res.message;
})

export const naloxoneBySiteSlice = createSlice({
  name: 'naloxone',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDashboardData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.records = action.payload;
      })
      .addCase(fetchDashboardData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchGraphData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchGraphData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.graphData = action.payload;
      })
      .addCase(fetchGraphData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchMapData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMapData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.mapData = action.payload;
      })
      .addCase(fetchMapData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchGraphCountyData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchGraphCountyData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.graph = action.payload;
      })
      .addCase(fetchGraphCountyData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchMapCountyData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMapCountyData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.map = action.payload;
      })
      .addCase(fetchMapCountyData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchExcelData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchExcelData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.exportRecords = action.payload;
      })
      .addCase(fetchExcelData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

// export const { fetchDashboardData } = naloxoneBySiteSlice.actions;

export default naloxoneBySiteSlice.reducer;
