import ExcelJS from 'exceljs'

function convertJsonToCsv(jsonData) {
  const headers = Object.keys(jsonData[0])
  const csv = [
    headers.join(','),
    ...jsonData.map((obj) => headers.map((header) => obj[header]).join(','))
  ].join('\n')
  return csv
}

function getColumnWidths(rows) {
  const headers = Object.keys(rows[0]);
  const columnWidths = headers.map(header => header.length);

  rows.forEach(row => {
    headers.forEach((header, index) => {
      const valueLength = String(row[header]).length;
      if (valueLength > columnWidths[index]) {
        columnWidths[index] = valueLength;
      }
    });
  });

  // Add some padding to the width for better readability
  return columnWidths.map(width => width + 2);
}

export const exportExcel = async ({ rows, fileName }) => {
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet('Sheet 1')

  const headers = Object.keys(rows[0]);
  worksheet.addRow(headers);

  const csvData = convertJsonToCsv(rows)?.split('\n');
  csvData.slice(1).forEach((data) => {
    worksheet.addRow(data.split(','));
  });

  const columnWidths = getColumnWidths(rows);
  columnWidths.forEach((width, index) => {
    worksheet.getColumn(index + 1).width = width;
  });

  // Generate Excel file
  const buffer = await workbook.xlsx.writeBuffer()

  // Save Excel file
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  })
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = `${fileName}.xlsx`
  a.click()
  URL.revokeObjectURL(url)
}
