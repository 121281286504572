import { useEffect, useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsMap from 'highcharts/modules/map'
import PropTypes from 'prop-types'
import { CircularProgress } from '@mui/material'
import usMapData from '@highcharts/map-collection/countries/us/us-all.geo.json'
import { mapClick } from '../../utils/mapClick'
import { backendRoutes } from '../../config/backendRoutes'
import { callGetAPI } from '../../api/api'

// Initialize the Highcharts Maps module
HighchartsMap(Highcharts)

const MapGraphSalesData = ({
  containerName,
  title,
  reset,
  mapData,
  isLoading = false,
  state,
  handleClick = () => null,
  setReset = () => null
}) => {
  const [data, setData] = useState([])
  const [titleCountyName, setTitleCountyName] = useState('')
  const [chart, setChart] = useState(null)

  // Function to click on Texas
  const clickTexas = () => {
    if (chart) {
      const texas = chart.series[0].points.find(
        (point) => point['hc-key'] === `us-${state?.State.toLowerCase()}`
      )
      if (texas) {
        texas.firePointEvent('click')
      }
    }
  }
  useEffect(() => {
    clickTexas()
  }, [state])

  useEffect(() => {
    const fn = async () => {
      setData(mapData)
      Highcharts.maps['countries/us/us-all'] = usMapData
    }

    fn()
  }, [reset, mapData])

  useEffect(() => {
    setReset(false)
    setTitleCountyName('')
  }, [data])
  useEffect(() => {
    const options = {
      chart: {
        map: `countries/us/us-all`
      },

      title: {
        text: titleCountyName ? title + ' - ' + titleCountyName : title,
        style: {
          fontSize: '13px' // Adjust the font size here
        }
      },

      mapNavigation: {
        enabled: true,
        buttonOptions: {
          verticalAlign: 'bottom'
        }
      },

      colorAxis: {
        min: 0,
        maxColor: '#0064a1',
        minColor: 'white'
      },

      tooltip: {
        formatter: function () {
          console.log(this.point.options)
          return (
            '<b>' +
            this.point.name +
            '</b><br>' +
            'Last 12 months: ' +
            this.point.options.value
          )
        }
      },
      series: [
        {
          data: data?.map(({ State, Sales }) => ({
            'hc-key': State,
            value: Sales
          })),
          mapData: Highcharts.maps['countries/us/us-all'],
          name: 'Random data',
          states: {
            hover: {
              color: 'lightblue'
            }
          },
          dataLabels: {
            enabled: false,
            format: '{point.name}'
          },
          point: {
            events: {
              click: async function () {
                handleClick(this['hc-key'].split('-')[1].toUpperCase())
                // console.log()
                const { message } = await callGetAPI({
                  path: `${backendRoutes.salesDataMapCounty}?query=${JSON.stringify({ state: this['hc-key'].split('-')[1].toUpperCase() })}`
                })
                mapClick({
                  data: this,
                  setData,
                  setTitleCountyName,
                  message,
                  type: 'salesdata'
                })
              }
            }
          }
        }
      ]
    }
    const mapChart = Highcharts.mapChart(containerName, options)
    setChart(mapChart)

    return () => {
      mapChart.destroy()
    }
  }, [isLoading, data])

  return (
    <>
      {isLoading && (
        <div
          style={{
            margin: 5,
            height: '400px',
            width: '100%',
            zIndex: 2000,
            alignContent: 'center',
            textAlignLast: 'center'
          }}
        >
          <CircularProgress />
        </div>
      )}
      <div
        id={containerName}
        style={{
          margin: 5,
          height: '400px',
          width: '100%',
          zIndex: 2000,
          alignContent: 'center',
          textAlignLast: 'center',
          display: isLoading ? 'none' : 'block'
        }}
      ></div>
    </>
  )
}

MapGraphSalesData.propTypes = {
  containerName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  reset: PropTypes.string,
  mapData: PropTypes.array,
  isLoading: PropTypes.bool,
  state: PropTypes.object,
  handleClick: PropTypes.func,
  setReset: PropTypes.func
}

export default MapGraphSalesData
