import { useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import SideBar from './components/SideBar/SideBar'
import Inventory from './pages/Inventory/Inventory'
import './App.css'
import SalesData from './pages/Inventory/SalesData'
import DispensedReport from './pages/Inventory/DispensedReport'
import RNIInventoryData from './pages/Inventory/RNIInventoryData'
import ProcessAuth from './pages/login/ProcessAuth'
import SessionTimeout from './pages/logout/session-timeout'
import AuthError from './pages/logout/auth-error'
import Logout from './pages/logout'
import { useSelector } from 'react-redux'

// Create your global variable
window.myGlobalVariable = 'This is a global variable'

const App = () => {
  const [open, setOpen] = useState(true)

  const authUser = useSelector((state) => state.users.user);

  const handleToggle = () => {
    setOpen(!open)
  }

  return (
    <Router>
      <ProcessAuth />
      {authUser &&
        <div style={{ display: 'flex' }}>
          <SideBar open={open} onClose={() => setOpen(false)} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: open ? 230 : 0,
              transition: 'margin-left 0.3s',
              width: '100%'
            }}
          >
            {/* <NavBar onMenuClick={handleToggle} open={open} /> */}
            {/* Main content goes here */}
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <Inventory
                    open={open}
                    title="Naloxone by Site"
                    onMenuClick={handleToggle}
                  />
                }
              />
              <Route
                exact
                path="/naloxoneOnHand"
                element={
                  <Inventory
                    open={open}
                    title="Naloxone by Site"
                    onMenuClick={handleToggle}
                  />
                }
              />
              <Route
                exact
                path="/salesData"
                element={
                  <SalesData
                    open={open}
                    title="Yearly Sales Data"
                    onMenuClick={handleToggle}
                  />
                }
              />
              <Route
                exact
                path="/dispensedReport"
                element={
                  <DispensedReport
                    open={open}
                    title="Pharmacies Dispense Report"
                    onMenuClick={handleToggle}
                  />
                }
              />
              <Route
                exact
                path="/zipViewRNI"
                element={
                  <RNIInventoryData
                    open={open}
                    title="Pharmacy Zip Level Inventory"
                    onMenuClick={handleToggle}
                  />
                }
              />
              <Route exact path="/session-timeout" component={SessionTimeout} />
              <Route exact path="/auth-error" component={AuthError} />
              <Route exact path="/logout" component={Logout} />
            </Routes>
          </div>
        </div>
      }
    </Router>
  )
}

export default App
