export const sideBarConfig = [
  {
    id: 'inventory',
    label: 'Inventory View',
    children: [
      {
        id: 'naloxoneOnHand',
        label: 'Naloxone by Site'
      },
      {
        id: 'salesData',
        label: 'Yearly Sales Data'
      },
      {
        id: 'dispensedReport',
        label: 'Pharmacies Dispense Report'
      },
      {
        id: 'zipViewRNI',
        label: 'Pharmacy Zip Level Inventory'
      }
    ]
  }
]
