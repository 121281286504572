import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { Avatar, Button } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { selectReportQueue, logoutUser } from '../../redux/users';
import { useNavigate } from 'react-router-dom';
import './MenuListComposition.css';

const MenuListComposition = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authUser = JSON.parse(localStorage.getItem('authUser'))
  const reportQueue = useSelector(selectReportQueue);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate('/logout');
  };

  useEffect(() => {
    if (!reportQueue?.length) return;
    const undownloadedCount = reportQueue.filter((queueItem) => !queueItem.hasBeenDownloaded).length;
    setUndownloadedInQueueCount(undownloadedCount);
  }, [reportQueue]);

  return (
    <>
      <div>
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className="profileMenuButton"
        >
          <Avatar className={'avatar'} ref={anchorRef}>
            {/* {`${authUser?.email}`} */}
          </Avatar>
          <MenuItem className={'profileName'}>{`${authUser?.email}`}</MenuItem>
          <ArrowDropDownIcon />
        </Button>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow">
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </>
  );
};

export default MenuListComposition;
