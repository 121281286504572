import {
  Autocomplete,
  Box,
  Button,
  Container,
  FormControl,
  TextField
} from '@mui/material'
import PropTypes from 'prop-types'
import './SearchAndFilters.css'
import { Popper } from '@material-ui/core'

const SearchAndFiltersDispensedReport = ({
  customersOptions,
  customer,
  setCustomer,
  setProduct,
  product,
  productsOptions,
  setCounty,
  county,
  countyOptions,
  setState,
  state,
  statesOptions,
  expiration,
  setExpiration,
  year,
  setYear,
  handleExport = () => null,
  setReset = () => null
}) => {
  return (
    <Container className="global-font">
      <Box display={'flex'} margin={'10px'} alignItems={'center'}>
        <FormControl
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '50%',
            height: '30px',
            margin: '10px 10px 10px 0px'
          }}
        >
          <Autocomplete
            key={customer}
            className="global-font autocomplete"
            labelId="customers"
            options={customersOptions}
            getOptionKey={(option) => option.Customer_ID}
            getOptionLabel={(option) => option.Customer_Name}
            value={customer}
            selectOnFocus
            renderInput={(params) => (
              <TextField {...params} label="Facilities" variant="standard" />
            )}
            onChange={(e, newValue) => setCustomer(newValue)}
            PopperComponent={(props) => (
              <Popper
                {...props}
                style={{ zIndex: 99999 }}
                placement="bottom-start"
              />
            )}
          />
        </FormControl>
        <Button
          variant="primary"
          className="global-font search-button"
          onClick={() => {
            setReset(true)
          }}
        >
          Reset
        </Button>
        <Button
          variant="primary"
          className="global-font search-button"
          onClick={handleExport}
        >
          Export
        </Button>
      </Box>
      <Box display={'flex'} margin={'10px'}>
        <FormControl
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '50%',
            height: '30px',
            margin: '10px 10px 10px 0px'
          }}
        >
          <Autocomplete
            key={product}
            className="global-font autocomplete"
            labelId="products"
            options={productsOptions}
            getOptionKey={(option) => option.product_id}
            getOptionLabel={(option) => option.product_name}
            value={product}
            renderInput={(params) => (
              <TextField {...params} label="Products" variant="standard" />
            )}
            onChange={(e, newValue) => setProduct(newValue)}
          />
        </FormControl>
        <FormControl
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '50%',
            height: '30px',
            margin: '10px 10px 10px 0px'
          }}
        >
          <Autocomplete
            key={county}
            className="global-font autocomplete"
            labelId="county"
            options={countyOptions}
            getOptionKey={(option) => option.County}
            getOptionLabel={(option) => option.County}
            value={county}
            renderInput={(params) => (
              <TextField {...params} label="County" variant="standard" />
            )}
            onChange={(e, newValue) => setCounty(newValue)}
          />
        </FormControl>
        <FormControl
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '50%',
            height: '30px',
            margin: '10px 10px 10px 0px'
          }}
        >
          <Autocomplete
            key={state}
            className="global-font autocomplete"
            labelId="products"
            options={statesOptions}
            getOptionKey={(option) => option.State}
            getOptionLabel={(option) => option.State}
            value={state}
            renderInput={(params) => (
              <TextField {...params} label="States" variant="standard" />
            )}
            onChange={(e, newValue) => setState(newValue)}
          />
        </FormControl>
        <FormControl
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '50%',
            height: '30px',
            margin: '10px 10px 10px 0px'
          }}
        >
          <Autocomplete
            key={expiration}
            className={`global-font autocomplete`}
            labelId="expiration"
            options={[
              { label: 'next 7 days', value: 7 },
              { label: 'next 30 days', value: 30 },
              { label: 'next 90 days', value: 90 }
            ]}
            getOptionKey={(option) => option.value}
            getOptionLabel={(option) => option.label}
            value={expiration}
            renderInput={(params) => (
              <TextField {...params} label="Expiration" variant="standard" />
            )}
            onChange={(e, newValue) => setExpiration(newValue)}
          />
        </FormControl>
        <FormControl
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '50%',
            height: '30px',
            margin: '10px 10px 10px 0px'
          }}
        >
          <Autocomplete
            key={year}
            clearIcon={null}
            className={`global-font autocomplete`}
            labelId="year"
            options={[
              { label: '2024', value: 2024 },
              { label: '2023', value: 2023 },
              { label: '2022', value: 2022 }
            ]}
            getOptionKey={(option) => option.value}
            getOptionLabel={(option) => option.label}
            value={year}
            renderInput={(params) => (
              <TextField {...params} label="Year" variant="standard" />
            )}
            onChange={(e, newValue) => setYear(newValue)}
          />
        </FormControl>
      </Box>
    </Container>
  )
}

SearchAndFiltersDispensedReport.propTypes = {
  customer: PropTypes.object,
  setCustomer: PropTypes.func.isRequired,
  customersOptions: PropTypes.array,
  product: PropTypes.object,
  setProduct: PropTypes.func.isRequired,
  productsOptions: PropTypes.array,
  county: PropTypes.object,
  setCounty: PropTypes.func.isRequired,
  countyOptions: PropTypes.array,
  state: PropTypes.object,
  setState: PropTypes.func.isRequired,
  statesOptions: PropTypes.array,
  expiration: PropTypes.object,
  setExpiration: PropTypes.func.isRequired,
  year: PropTypes.object,
  setYear: PropTypes.func.isRequired,
  setReset: PropTypes.func,
  handleExport: PropTypes.func
}

export default SearchAndFiltersDispensedReport
