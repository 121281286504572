import { useEffect, useState } from 'react'
import { Image as IMG } from 'react-bootstrap'
import { Drawer, List, ListItem, ListItemText } from '@material-ui/core'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { ListItemButton } from '@mui/material'
import { Home } from '@material-ui/icons'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { sideBarConfig } from './sideBarConfig'
import './SideBar.css'

const SideBar = ({ open }) => {
  const [openItems, setOpenItems] = useState({})
  const { pathname } = useLocation() // Get current pathname from React Router

  useEffect(() => {
    // Close all items initially
    const initialOpenItems = {}
    sideBarConfig.forEach((item) => {
      initialOpenItems[item.id] = false
      if (item.children) {
        item.children.forEach((child) => {
          initialOpenItems[child.id] = false
        })
      }
    })
    setOpenItems(initialOpenItems)

    // Set active route item to open
    const activeItem = sideBarConfig.find((item) => {
      const activeChildren = item.children?.find(
        (itm) => itm.id === pathname.slice(1)
      )
      return item.id === pathname.slice(1) || activeChildren
    })
    console.log(activeItem, pathname, pathname.slice(1))
    if (activeItem) {
      setOpenItems((prevOpenItems) => ({
        ...prevOpenItems,
        [activeItem.id]: true
      }))
    }
  }, [pathname])

  const handleClick = (itemId) => {
    setOpenItems((prevOpenItems) => ({
      ...prevOpenItems,
      [itemId]: !prevOpenItems[itemId]
    }))
  }

  const renderItems = (items = []) => {
    return items?.map((item) => {
      const { id, label, children } = item
      const isOpen = openItems[id]

      const isActive = pathname.slice(1) === id
      return (
        <>
          {children ? (
            <>
              <ListItemButton
                onClick={() => handleClick(id)}
                className={isActive ? 'active' : ''}
              >
                <Home className="nav-link" style={{ marginRight: '10' }} />
                <ListItemText
                  href={`${id}`}
                  className="nav-link"
                  primary={label}
                  style={{ paddingLeft: children ? 0 : '35px' }}
                />
                {isOpen ? (
                  <ExpandLess className="nav-link" />
                ) : (
                  <ExpandMore className="nav-link" />
                )}
              </ListItemButton>
              <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {renderItems(children)}
                </List>
              </Collapse>
            </>
          ) : (
            <ListItemButton
              href={`${id}`}
              onClick={() => handleClick(id)}
              className={isActive ? 'active' : ''}
            >
              <ListItemText
                href={`${id}`}
                className="nav-link"
                primary={label}
                style={{ paddingLeft: children ? 0 : '35px' }}
              />
            </ListItemButton>
          )}
        </>
      )
    })
  }
  return (
    <Drawer
      className={'global-font sidebar'}
      open={open}
      BackdropProps={{ invisible: true }}
    >
      <ListItem button href="/">
        <IMG
          src="https://www.fffenterprises.com/assets/images/logo.svg"
          alt="Sample Image"
          height={'50px'}
          width={'100%'}
          style={{ height: '100px !important' }}
        />
      </ListItem>
      <List
        className="global-font"
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {renderItems(sideBarConfig)}
      </List>
    </Drawer>
  )
}

SideBar.propTypes = {
  open: PropTypes.string
}

export default SideBar
