import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import 'firebase/functions';
import config from '../config';

// Initialize Firebase
const app = initializeApp(config.firebase);

// Initialize Firebase services
const auth = getAuth(app);
// const firestore = getFirestore(app);

export { app, auth };