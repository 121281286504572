import PropTypes from 'prop-types'
import MUIDataTable from 'mui-datatables'

const MUIDataTables = ({
  records,
  colDef = []
}) => {
  const preprocessedData = records.map((item) => ({
    ...item,
    Expiration: item.Expiration?.value,
    Date: item.Date?.value
  }))

  return (
    <div style={{ width: '100%', height: '100%', zIndex: 2000 }}>
      <MUIDataTable
        data={preprocessedData}
        columns={colDef}
        options={{
          rowsPerPageOptions: [5, 10, 20, 30],
          rowsPerPage: 5,
          search: false, // Disable search
          print: false // Disable print
        }}
      />
    </div>
  )
}

MUIDataTables.propTypes = {
  records: PropTypes.array,
  totalRecords: PropTypes.number,
  isLoading: PropTypes.bool,
  paginationModel: PropTypes.object,
  setPaginationModel: PropTypes.func,
  colDef: PropTypes.array,
  columnVisibilityModel: PropTypes.object
}

export default MUIDataTables
