import Highcharts from 'highcharts'
import { dynamicImportGenerator } from './dynamicImportGenerator'

export const mapClick = async ({
  data,
  setData,
  setTitleCountyName,
  message,
  type = 'onhand'
}) => {
  const counties = []
  // const { message } = await callGetAPI({
  //   path: `${backendRoutes.naloxoneOnHandMapCounty}?state=${data['hc-key'].split('-')[1].toUpperCase()}`
  // })
  const importMapModule = dynamicImportGenerator({ mapName: data['hc-key'] })

  try {
    const response = await fetch(importMapModule)
    if (!response.ok) {
      throw new Error('Failed to fetch map data')
    }
    const mapData = await response.json()
    Highcharts.maps['countries/us/us-all'] = mapData

    for (let county of message) {
      switch (type) {
        case 'onhand':
          counties.push({
            State: `${data['hc-key']}-${county?.extracted_fips}`,
            occurence: parseInt(county?.extracted_fips),
            Status_Pending_Quantity: county?.Status_Pending_Quantity,
            Status_Transmitted_Quantity: county?.Status_Transmitted_Quantity
          })
          break
        case 'salesdata':
          counties.push({
            State: `${data['hc-key']}-${county?.extracted_fips}`,
            Sales: parseInt(county?.Sales)
          })
          break
        case 'dispensedreport':
          counties.push({
            State: `${data['hc-key']}-${county?.extracted_fips}`,
            Dispensed_Qty: parseInt(county?.Dispensed_Qty),
            Pharmacies: parseInt(county?.Pharmacies)
          })
          break
      }
    }
    setData(counties)
    setTitleCountyName(data['name'])
  } catch (error) {
    console.error('Error loading map module:', error)
  }
}
