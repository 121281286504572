export const backendRoutes = {
  naloxoneOnHand: '/naloxone',
  naloxoneOnHandMap: '/naloxone/map',
  naloxoneOnHandMapCounty: '/naloxone/map/county',
  naloxoneOnHandGraph: '/naloxone/graph',
  naloxoneOnHandGraphCounty: '/naloxone/graph/county',
  naloxoneOnHandExport: '/naloxone/export',
  customersOptions: '/naloxone/customers',
  productsOptions: '/naloxone/products',
  countyOptions: '/naloxone/county',
  statesOptions: '/naloxone/states',
  zipCodeOptions: '/naloxone/zipCode',
  salesData: '/naloxone/sales-data',
  salesDataMap: '/naloxone/sales-data/map',
  salesDataMapCounty: '/naloxone/sales-data/map/county',
  salesDataGraph: '/naloxone/sales-data/graph',
  salesDataGraphCounty: '/naloxone/sales-data/graph/county',
  salesDataExport: '/naloxone/sales-data/export',
  dispensedReport: '/naloxone/dispensed-report',
  dispensedReportMap: '/naloxone/dispensed-report/map',
  dispensedReportMapCounty: '/naloxone/dispensed-report/map/county',
  dispensedReportGraph: '/naloxone/dispensed-report/graph',
  dispensedReportGraphCounty: '/naloxone/dispensed-report/graph/county',
  dispensedReportExport: '/naloxone/dispensed-report/export',
  inventoryData: '/naloxone/inventory-data',
  inventoryDataMap: '/naloxone/inventory-data/map',
  inventoryDataMapCounty: '/naloxone/inventory-data/map/county',
  inventoryDataGraph: '/naloxone/inventory-data/graph',
  inventoryDataGraphCounty: '/naloxone/inventory-data/graph/county',
  inventoryDataExport: '/naloxone/inventory-data/export'
}
