import local from './config/local.json';
import devNaloxone from './config/dev-naloxone.json'

const configFiles = {
  'dev-naloxone': devNaloxone
}

function getConfig() {
  // @ts-ignore
  const subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;

  
  if (!subdomain) {
    return local;
  }
  

  return configFiles[subdomain];
}

export default (getConfig());
