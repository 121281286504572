// src/counterSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { callGetAPI } from '../api/api';
import { backendRoutes } from '../config/backendRoutes';

const initialState = {
  productsOptions: [],
  customersOptions: [],
  statesOptions: [],
  countyOptions: [],
  zipCodeOptions: [],
  status: '',
  error: ''
};

export const fetchProductsOptions = createAsyncThunk('app/fetchProductsOptions', async () => {
  const res = await callGetAPI({
    path: `${backendRoutes.productsOptions}`
  })
  return res.message;
})

export const fetchCustomersOptions = createAsyncThunk('app/fetchCustomersOptions', async () => {
  const res = await callGetAPI({
    path: `${backendRoutes.customersOptions}`
  })
  return res.message;
})
export const fetchCountyOptions = createAsyncThunk('app/fetchCountyOptions', async () => {
  const res = await callGetAPI({
    path: `${backendRoutes.countyOptions}`
  })
  return res.message;
})
export const fetchStateOptions = createAsyncThunk('app/fetchStateOptions', async () => {
  const res = await callGetAPI({
    path: `${backendRoutes.statesOptions}`
  })
  return res.message;
})

export const fetchZipCodeOptions = createAsyncThunk('app/fetchZipCodeOptions', async () => {
  const res = await callGetAPI({
    path: `${backendRoutes.zipCodeOptions}`
  })
  return res.message;
})

export const dropdownSlice = createSlice({
  name: 'dropdowns',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductsOptions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProductsOptions.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.productsOptions = action.payload;
      })
      .addCase(fetchProductsOptions.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchCustomersOptions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCustomersOptions.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.customersOptions = action.payload;
      })
      .addCase(fetchCustomersOptions.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchCountyOptions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCountyOptions.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.countyOptions = action.payload;
      })
      .addCase(fetchCountyOptions.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchStateOptions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchStateOptions.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.statesOptions = action.payload;
      })
      .addCase(fetchStateOptions.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchZipCodeOptions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchZipCodeOptions.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.zipCodeOptions = action.payload;
      })
      .addCase(fetchZipCodeOptions.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default dropdownSlice.reducer;
