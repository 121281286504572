export const salesColDef = [
  {
    name: 'Customer_ID',
    label: 'Customer ID'
  },
  {
    name: 'Customer_Name',
    label: 'Customer Name'
  },
  {
    name: 'Cabinet_ID',
    label: 'Cabinet ID'
  },
  {
    name: 'Cabinet_Name',
    label: 'Cabinet Name'
  },
  {
    name: 'Cabinet_Type',
    label: 'Cabinet Type'
  },
  {
    name: 'Year',
    label: 'Year'
  },
  {
    name: 'Product_ID',
    label: 'Product ID'
  },
  {
    name: 'Product_Name',
    label: 'Product Name'
  },
  {
    name: 'Lot',
    label: 'Lot'
  },
  {
    name: 'Serial_No',
    label: 'Serial No'
  },
  {
    name: 'Date',
    label: 'Date',
    customBodyRender: (value) => value.value
  },
  {
    name: 'Sales_Doc_type',
    label: 'Sales Doc_type'
  },
  {
    name: 'ZIP',
    label: 'ZIP'
  },
  {
    name: 'State',
    label: 'State'
  },
  {
    name: 'City',
    label: 'City'
  },
  {
    name: 'FIPS',
    label: 'FIPS'
  },
  {
    name: 'County',
    label: 'County'
  },
  {
    name: 'Quantity',
    label: 'Quantity'
  },
  {
    name: 'Status',
    label: 'Status'
  },
  {
    name: 'Month',
    label: 'Month'
  },
  {
    name: 'id',
    label: 'id'
  }
]
