// src/components/ProcessAuth.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthProcessing, handleRniAuthToken, handleRniAuthPageRefresh } from '../../redux/users';
import Loading from '../../components/Loading';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Button } from '@material-ui/core';
import config from '../../config';

const ProcessAuth = () => {
  const [isProcessing, setIsProcessing] = useState(true);
  const [authError, setAuthError] = useState(false);
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.users.user);

  const doRniAuth = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const authTokenInUrl = queryParams.get('authToken');
    if (!authTokenInUrl) {
      const idToken = localStorage.getItem('id_token');
      const authTokenInSession = localStorage.getItem('rniAuthToken');
      if (idToken || authTokenInSession) {
        dispatch(handleRniAuthPageRefresh());
        return true;
      }
      return false;
    }
    window.history.pushState({}, document.title, window.location.pathname);
    localStorage.setItem('rniAuthToken', authTokenInUrl);
    dispatch(handleRniAuthToken(authTokenInUrl));
    return true;
  };

  const redirectToSignOn = () => {
    const authUrl = config.authUrl;
    const callbackUrl = config.authCallbackUrl || window.location.origin;
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = `${authUrl}?callbackUrl=${callbackUrl}`;
  };

  const bypassAuth = () => {
    const path = window.location.pathname;
    return ['logout', 'session-timeout', 'auth-error', 'user-setup'].some((segment) =>
      path.includes(segment)
    );
  };

  useEffect(() => {
    if (isProcessing) {
      dispatch(setAuthProcessing(true));
    }

    if (bypassAuth()) {
      dispatch(setAuthProcessing(false));
      return;
    }

    if (authUser) {
      setIsProcessing(false);
      dispatch(setAuthProcessing(false));
      return;
    }

    if (!doRniAuth()) {
      redirectToSignOn();
    }
  }, [isProcessing, authUser, dispatch]);

  const authFailureContent = authError ? (
    <div style={{ width: '50%', margin: 'auto' }}>
      <Alert
        severity="error"
        action={
          <Button color="inherit" size="small" variant="text" onClick={redirectToSignOn}>
            Log In
          </Button>
        }
      >
        <AlertTitle>Authentication Failure</AlertTitle>
        We were unable to authenticate you at this time. Please try logging in again.
      </Alert>
    </div>
  ) : (
    ''
  );

  return (
    <div>
      {!bypassAuth() && isProcessing ? <Loading message="Processing..." /> : <div>{authFailureContent}</div>}
    </div>
  );
};

export default ProcessAuth;
